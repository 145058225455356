const LOGIN = "auth/login/admin";
const CREATE_ADMIN = "auth/register/admin";
const VERIFY_ADMIN = "auth/approve/admin";
const APPROVE_ADMIN = "auth/login/admin/approve";
const REFRESH_TOKEN = "auth/refresh-token";
const FORGOT_PASSWORD = "auth/reset-password/request";
const RESET_PASSWORD = "auth/reset-password/confirm";
const AD_PERFORMING_METRICS = "analytics/ad-performing-metrics";
const REVENUE_BAR_CHART = "analytics/ad-revenue-bar-chart";
const TRIBE_REVENUE_PIE_CHART = "analytics/tribe-revenue-pie-chart";
const EXPORT_METRICS = "analytics/export-shekels-metrics";
const METRICS = "analytics/metrics";
const SHEKEL_METRICS = "analytics/shekels-metrics";
const TOP_PERFORMING_ADS = "advertisement-metrics/top-performing-ads";
const TOP_PERFORMING_TRIBES = "advertisement-metrics/top-performing-tribes";
const TOP_WATCHERS = "advertisement-metrics/top-watchers";
const TOP_REVENUE_ADS = "advertisement-metrics/top-revenue-ads";
const USERS = "admin/users";
const USERS_SEARCH = "admin/users/search";
const ADVERTISEMENTS = "advertisements";
const deleteAdComment = (adId: string, commentId: string) =>
  `advertisement-engagements/${adId}/comment/${commentId}`;
const getAdComments = (id: string) =>
  `advertisement-engagements/${id}/comments`;
const deleteAdCommentReply = (
  adId: string,
  commentId: string,
  replyId: string
) => `advertisement-engagements/${adId}/comment/${commentId}/reply/${replyId}`;
const getAdCommentReplies = (adId: string, commentId: string) =>
  `advertisement-engagements/${adId}/comments/${commentId}/replies`;
const ALL_TRIBES = "tribes";
const TOP_VIEWERS_LOCATION = "advertiser-metrics/top-viewers-location";
const TOP_WATCHERS_GENDER = "advertiser-metrics/top-watchers-gender";
const TOP_WATCHERS_AGE = "advertiser-metrics/top-watchers-age";
const ADS_WATCHED = "admin/users/watched-ads";
const WATCHER_STATS = "admin/users/single-watchers-stats";
const TRANSACTIONS = "transactions";
const REPORT_TRANSACTIONS = "content-reports/transaction";
const REVENUE_TRACKING = "analytics/budget-allocation-history";

const Endpoints = {
  LOGIN,
  CREATE_ADMIN,
  APPROVE_ADMIN,
  REFRESH_TOKEN,
  VERIFY_ADMIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REVENUE_BAR_CHART,
  TRIBE_REVENUE_PIE_CHART,
  AD_PERFORMING_METRICS,
  SHEKEL_METRICS,
  METRICS,
  TOP_PERFORMING_ADS,
  TOP_REVENUE_ADS,
  TOP_PERFORMING_TRIBES,
  TOP_WATCHERS,
  USERS,
  USERS_SEARCH,
  ADVERTISEMENTS,
  ALL_TRIBES,
  TOP_VIEWERS_LOCATION,
  TOP_WATCHERS_GENDER,
  TOP_WATCHERS_AGE,
  ADS_WATCHED,
  WATCHER_STATS,
  deleteAdComment,
  getAdComments,
  deleteAdCommentReply,
  getAdCommentReplies,
  TRANSACTIONS,
  REPORT_TRANSACTIONS,
  REVENUE_TRACKING,
} as const;

export default Endpoints;
