import { Flex } from "antd";
import styled from "styled-components";

export const AccountCardWrapper = styled.div`
  width: 100%;
  max-width: 516px;
  margin: 0 auto;
`;

export const ValueInNaira = styled.div`
  background-color: ${({ theme }) => theme.color.black50};
  padding: 6px 16px;
  border-radius: 33px;
`;

export const ShekelConversions = styled.div`
  background-color: ${({ theme }) => theme.color.black100};
  padding: 8px 24px;
  border-radius: 33px;
`;

export const StyledTableImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const StyledModalButtonContainer = styled(Flex)`
  & > * {
    width: 100%;
  }
`;

export const StyledSuccessContainer = styled(Flex)`
  max-width: 361px;
  margin: 0 auto;
`;
