import {
  AdminFieldType,
  IAdminManagementFormProps,
} from "./AdminManagement.types";
import { Form } from "antd";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import Modal from "@/components/Dashboard/Modal/Modal";
import Select from "@/components/Dashboard/Select/Select";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  StyledAdminFormActionButtonContainer,
  StyledImageUpload,
  StyledUploadButton,
} from "./AdminManagement.styles";
import { required } from "@/utils/validation";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { UserTwo } from "@/assets/vectors";
import { useState } from "react";

const AdminManagementForm: React.FC<IAdminManagementFormProps> = ({
  isLoading,
  onSubmit,
  isOpen,
  setIsOpen,
  type,
}) => {
  const [form] = Form.useForm<AdminFieldType>();
  const [imageUrl, setImageUrl] = useState<string>();

  const hideModal = () => {
    setIsOpen(false);
  };

  const onFinish = (values: AdminFieldType) => {
    console.log("Success:", values);
    onSubmit(values);
  };

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      heading={type === "create" ? "Add an Admin User" : "Edit Admin User"}
      footer={
        <StyledAdminFormActionButtonContainer justify="stretch">
          <StyledButton onClick={hideModal} type="default">
            Cancel
          </StyledButton>
          <StyledButton
            loading={isLoading}
            disabled={isLoading}
            form={type === "create" ? "add-admin" : "edit-admin"}
            key="submit"
            htmlType="submit"
            type="primary"
          >
            Done
          </StyledButton>
        </StyledAdminFormActionButtonContainer>
      }
    >
      <StyledForm
        form={form}
        layout="vertical"
        name={type === "create" ? "add-admin" : "edit-admin"}
        onFinish={onFinish}
      >
        <StyledFlex gap={16} vertical $alignSelf="stretch">
          <StyledFlex gap={20} align="center">
            <StyledImageUpload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              //   beforeUpload={beforeUpload}
              //   onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <UserTwo />
              )}
            </StyledImageUpload>

            <StyledUploadButton type="button">
              <StyledTypography $variantColor="black100" $variantSize="sm">
                Upload image
              </StyledTypography>
            </StyledUploadButton>
          </StyledFlex>
          <StyledForm.Item<AdminFieldType>
            name="firstName"
            rules={[required("Please input the first name!")]}
            label="First Name"
          >
            <StyledInput type="text" placeholder="Enter First Name" />
          </StyledForm.Item>
          <StyledForm.Item<AdminFieldType>
            name="lastName"
            rules={[required("Please input the last name!")]}
            label="Last Name"
          >
            <StyledInput type="text" placeholder="Enter Last Name" />
          </StyledForm.Item>
          <StyledForm.Item<AdminFieldType>
            name="email"
            rules={[
              required("Please input your Email!"),
              {
                type: "email",
                message: "Please input a valid Email",
              },
            ]}
            label="Email Address"
          >
            <StyledInput type="text" placeholder="Enter Email" />
          </StyledForm.Item>
          <StyledForm.Item<AdminFieldType>
            name="phoneNumber"
            rules={[required("Please input your Phone number!")]}
            label="Phone Number"
          >
            <StyledInput
              type="text"
              placeholder="Enter Phone number (+234XXXXXXXXXX)"
            />
          </StyledForm.Item>
          <StyledForm.Item<AdminFieldType>
            name="role"
            rules={[required("Please input your role")]}
            label="Role"
          >
            <Select
              placeholder="Select a Role type"
              options={[
                { value: "role1", label: "Role 1" },
                { value: "role2", label: "Role 2" },
                { value: "role3", label: "Role 3" },
              ]}
            />
          </StyledForm.Item>
        </StyledFlex>
      </StyledForm>
    </Modal>
  );
};

export default AdminManagementForm;
