import styled from "styled-components";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";

export const StyledProfileWrapper = styled(StyledFlex)`
  padding: 0 32px;
`;

export const StyledProfileForm = styled(StyledForm)`
  flex-grow: 1;
`;
