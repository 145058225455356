import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { SingleAdLayout, SingleAdMain } from "./SingleAd.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import GoBack from "@/components/Dashboard/GoBack/GoBack";
import AdProfile from "@/components/Dashboard/Analytics/AdProfile/AdProfile";
import AdComments from "@/components/Dashboard/Analytics/AdComments/AdComments";
import { useLocation, useParams } from "react-router-dom";
import { QUERY_KEY_ADVERTISEMENT } from "@/constants/api";
import { getAdvert } from "@/network/advertisement";
import { useQuery } from "@tanstack/react-query";

const SingleAd = () => {
  const params = useParams();
  const id = params?.id;

  const { data: advertData, isPending: isPendingAdvert } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISEMENT, id],
    queryFn: async () => (id ? getAdvert(`${id}`) : undefined),
  });

  if (advertData && "error" in advertData) {
    return null;
  }

  const advert = advertData?.data?.advert;
  const profile = advert?.user?.profile;
  const metadata = advert?.metadata;

  return (
    <SingleAdLayout gap={32} vertical $alignSelf="stretch">
      <StyledTypography
        $variantColor="gray90"
        $variantSize="2xl"
        $fontWeight={500}
      >
        Ad Management
      </StyledTypography>
      <StyledDivider />
      <SingleAdMain gap={48} vertical $alignSelf="stretch">
        <GoBack />
        <AdProfile
          fullName={`${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`}
          advertiser={`${profile?.username ?? ""}`}
          comments={metadata?.totalComments ?? 0}
          description={advert?.description ?? ""}
          likes={metadata?.totalLikes ?? 0}
          thumbnail={advert?.thumbnail?.publicURL ?? "s"}
          title={advert?.title ?? ""}
          videoUrl={advert?.video?.publicURL ?? ""}
          views={metadata?.totalViews ?? 0}
          profilePicture={profile?.avatar?.publicURL ?? ""}
          questions={advertData?.data?.questions ?? []}
          allocationPerWatcher={advert?.costPerView ?? 0}
          budget={advert?.maxSpend ?? 0}
        />
        <StyledDivider />
        <AdComments />
      </SingleAdMain>
    </SingleAdLayout>
  );
};

export default SingleAd;
