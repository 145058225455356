import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Flex, Upload } from "antd";
import styled from "styled-components";

export const AdminManagementLayout = styled(StyledFlex)`
  width: 100%;
`;

export const StyledTableImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const StyledActionBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const StyledAdminFormActionButtonContainer = styled(Flex)`
  & > * {
    width: 50%;
  }
`;

export const StyledImageUpload = styled(Upload)`
  &.ant-upload-picture-card-wrapper {
    width: 120px;
    height: 120px;
    position: relative;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: ${({ theme }) => `1px solid ${theme.color.primaryLight3}`};
    background-color: ${({ theme }) =>
      `1px solid ${theme.color.primaryLight3}`};
    &:hover {
      border-color: ${({ theme }) => theme.color.primary};
    }
  }
`;

export const StyledUploadButton = styled.button`
  border-radius: 33px;
  border: ${({ theme }) => `1px solid ${theme.color.black70}`};
  padding: 8px 16px;
  /* position: absolute; */
  /* right: calc(100% + 20px);
  top: 50%;
  transform: translateY(-50%); */
`;
