import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdminManagementLayout,
  StyledActionBtn,
  StyledTableImg,
} from "./AdminManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { Delete, Edit, Plus, Search } from "@/assets/vectors";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import AvartarImg from "@/assets/images/avartar.png";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import Table from "@/components/Dashboard/Table/Table";
import { useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import Select from "@/components/Dashboard/Select/Select";
import { AdminFieldType } from "./AdminManagement.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { useMutation } from "@tanstack/react-query";
import { createAdmin } from "@/network/auth";
import { Form, message } from "antd";
import AdminManagementForm from "./AdminManagementForm";
interface DataType {
  key: string;
  name: string;
  email: string;
  image: string;
  date: string;
  role: string;
}

const AdminManagement = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { mutate, data, isPending } = useMutation({
    mutationFn: createAdmin,
  });

  const showModal = () => {
    setIsAddModalOpen(true);
  };

  const onFinish = (values: AdminFieldType) => {
    console.log("Success:", values);
    mutate({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      redirectUrl: "https://lohli-admin-frontend.vercel.app/verify-signup",
    });
  };

  if (data) {
    if ("statusCode" in data) {
      message.error(data.message);
    } else {
      message.success(data.message);
    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "name",
      render: (_, { name }) => (
        <StyledFlex gap={12} align="center">
          <StyledTableImg src={AvartarImg} alt="" />
          <StyledTypography $variantColor="gray90" $variantSize="sm">
            {name}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "34%",
    },
    {
      title: "Email address",
      key: "email",
      render: () => (
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          sampleemail@email.com
        </StyledTypography>
      ),
      width: "22%",
    },
    {
      title: "Role",
      key: "role",
      render: () => (
        // <StyledPill $bgColor="success50" $color="success700">
        //   Relationship Manager
        // </StyledPill>
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          Relationship Manager
        </StyledTypography>
      ),
      width: "19%",
    },
    {
      title: "Date added",
      key: "date",
      render: () => (
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          June 10, 2022
        </StyledTypography>
      ),
      width: "14%",
    },
    {
      title: "",
      key: "key",
      render: () => (
        <StyledFlex gap={4} align="center">
          <StyledActionBtn>
            <Delete />
          </StyledActionBtn>
          <StyledActionBtn
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          >
            <Edit />
          </StyledActionBtn>
        </StyledFlex>
      ),
      width: "11%",
    },
  ];

  return (
    <AdminManagementLayout gap={32} vertical $alignSelf="stretch">
      <AdminManagementForm
        type="create"
        isLoading={isPending}
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        onSubmit={(values) => {
          onFinish(values);
        }}
      />
      <AdminManagementForm
        type="edit"
        isLoading={false}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSubmit={(values) => {
          onFinish(values);
        }}
      />
      <StyledFlex align="center" justify="space-between">
        <StyledFlex vertical gap={4}>
          <StyledFlex align="center" gap={8}>
            <StyledTypography
              $variantColor="gray90"
              $variantSize="xl-plus"
              $fontWeight={500}
            >
              Admin Members
            </StyledTypography>
            <StyledPill $bgColor="secondary2" $color="black100">
              7 users
            </StyledPill>
          </StyledFlex>
          <StyledTypography
            $variantColor="gray500"
            $variantSize="md"
            $fontWeight={400}
          >
            Manage your admin members here
          </StyledTypography>
        </StyledFlex>
        <StyledButton onClick={() => showModal()} type="primary">
          <Plus />
          <span>Add Admin User</span>
        </StyledButton>
      </StyledFlex>
      <Table
        columns={columns}
        pageSize={10}
        isPaginated
        dataSource={[
          {
            key: "0",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "1",
            name: "Omolara Fredrick",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "2",
            name: "Adekunle Onobanjo",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "3",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "4",
            name: "Omolara Fredrick",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "5",
            name: "Adekunle Onobanjo",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "6",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
        ]}
      />
    </AdminManagementLayout>
  );
};

export default AdminManagement;
