import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  StyledInputWrapper,
  StyledRoleWrapper,
  StyledSection,
  StyledSwitchWrapper,
} from "./RoleForm.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { ChevronLeft } from "@/assets/vectors";
import { IRoleFormProps, RoleDetailsType } from "./RoleForm.types";
import { Form } from "antd";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import { useNavigate } from "react-router-dom";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledSwitch } from "@/components/Dashboard/Switch/Switch.styles";
import { StyledForm } from "../../Form/Form.styles";
import { required } from "@/utils/validation";
import { useEffect } from "react";

const RoleForm: React.FC<IRoleFormProps> = ({
  initialDescription,
  initialName,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<RoleDetailsType>();

  return (
    <StyledRoleWrapper vertical gap={64}>
      <StyledFlex gap={10}>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft />
        </button>
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Add new role
        </StyledTypography>
      </StyledFlex>
      <StyledFlex vertical gap={32}>
        <Form
          form={form}
          layout="vertical"
          name="add-role"
          onFinish={(values) => {
            console.log(values);
          }}
          initialValues={{
            name: initialName,
            description: initialDescription,
          }}
        >
          <StyledSection vertical gap={32}>
            <StyledFlex vertical gap={4}>
              <StyledTypography
                $variantColor="gray90"
                $variantSize="xl-plus"
                $fontWeight={500}
              >
                Roles Details
              </StyledTypography>
              <StyledTypography $variantColor="gray500" $variantSize="md">
                Update the details of this role.
              </StyledTypography>
            </StyledFlex>
            <StyledFlex gap={10} align="flex-start" justify="space-between">
              <StyledTypography
                $variantColor="gray700"
                $variantSize="md"
                $fontWeight={600}
              >
                Name of role
              </StyledTypography>

              <StyledInputWrapper>
                <StyledForm.Item<RoleDetailsType>
                  name="name"
                  rules={[required("Please input the name!")]}
                >
                  <StyledInput
                    type="text"
                    placeholder="eg, Relationship officer "
                  />
                </StyledForm.Item>
              </StyledInputWrapper>
            </StyledFlex>
            <StyledFlex gap={10} align="flex-start" justify="space-between">
              <StyledFlex vertical gap={4}>
                <StyledTypography
                  $variantColor="gray700"
                  $variantSize="md"
                  $fontWeight={600}
                >
                  Role description
                </StyledTypography>
                <StyledTypography $variantColor="gray500" $variantSize="sm">
                  Role description
                </StyledTypography>
              </StyledFlex>

              <StyledInputWrapper>
                <StyledForm.Item<RoleDetailsType>
                  name="description"
                  rules={[required("Please input the description!")]}
                >
                  <StyledTextArea placeholder="Please enter a description " />
                </StyledForm.Item>
              </StyledInputWrapper>
            </StyledFlex>
          </StyledSection>
        </Form>
        <StyledDivider />
        <StyledSection vertical gap={44}>
          <StyledFlex vertical gap={4}>
            <StyledTypography
              $variantColor="gray90"
              $variantSize="xl-plus"
              $fontWeight={600}
            >
              Assign permissions
            </StyledTypography>
            <StyledTypography $variantColor="gray500" $variantSize="md">
              Assign the permissions and access for team members with this role.
            </StyledTypography>
          </StyledFlex>

          {/* Permissions list starts here */}
          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Shekels and Financials
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can update shekels conversion rate
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Ad management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can view all ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can suspend ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can ban ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Ad management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can view all ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can suspend ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can ban ads
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Watcher’s management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can view all watchers profile
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can edit watcher’s profile
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can delete watcher
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can suspend watcher
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can ban watcher
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Advertiser’s management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can view all advertisers
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can edit advertiser’s profile
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can delete advertiser
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can suspend advertiser
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can ban advertiser
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Tribe management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can add new tribe
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can edit tribe
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can delete tribe
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Admin management
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can add new admin
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can edit admin details
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can delete admin
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          <StyledFlex gap={10} align="flex-start" justify="space-between">
            <StyledTypography
              $variantColor="gray700"
              $variantSize="md"
              $fontWeight={600}
            >
              Settings(account balance threshold)
            </StyledTypography>
            <StyledSwitchWrapper vertical gap={22}>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can add shekels
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
              <StyledFlex gap={10} align="center" justify="space-between">
                <StyledTypography $variantColor="gray500" $variantSize="md">
                  Can set threshold
                </StyledTypography>
                <StyledSwitch />
              </StyledFlex>
            </StyledSwitchWrapper>
          </StyledFlex>

          {/* Permissions list ends here */}
        </StyledSection>
      </StyledFlex>
    </StyledRoleWrapper>
  );
};

export default RoleForm;
