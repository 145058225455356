const color = {
  primary: "#0062D6",
  secondary: "#E5C487",
  secondary2: "#EAD159",
  secondaryLight: "#E5C48773",
  primaryLight: "#B0CEF2",
  primaryLight2: "#0A7AFF",
  primaryLight3: "#F0F7FF",
  white: "#FFFFFF",
  success50: "#ECFDF3",
  success700: "#027A48",
  black50: "#F2F2F2",
  black60: "#E4E4E4",
  black70: "#C8C8C8",
  black80: "#9F9F9F",
  black90: "#646464",
  black100: "#353535",
  gray50: "#F9FAFB",
  gray90: "#101828",
  gray100: "#F5F5F5",
  gray200: "#E4E7EC",
  gray300: "#D0D5DD",
  gray500: "#667085",
  grey500: "#25272A",
  grey600: "#E8E8E8",
  gray700: "#344054",
  gray800: "#1D2939",
  gray900: "#EBE2D6",
  brown500: "#EBE2D6",
  red: "#E33F3F",
  green: "#44B480",
  yellow: "#F3E4C9",
  error50: "#FEF3F2",
  error700: "#B42318",
} as const;

export default color;
