import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const StyledRoleWrapper = styled(StyledFlex)`
  width: 100%;
`;

export const StyledSection = styled(StyledFlex)`
  max-width: 852px;
  width: 100%;
  padding: 0 32px;
`;

export const StyledInputWrapper = styled.div`
  max-width: 389px;
  flex-grow: 1;
`;

export const StyledSwitchWrapper = styled(StyledFlex)`
  max-width: 412px;
  flex-grow: 1;
`;
