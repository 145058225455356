import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    border-bottom: none;
  }
  & .ant-tabs-nav {
    margin-bottom: 32px;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  }
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    gap: 16px;
  }
  & .ant-tabs-tab {
    height: 42px;
    padding: 0 4px 16px 4px;
  }
  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  & > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 42px;
    background-color: ${({ theme }) => theme.color.white};
    border-bottom: ${({ theme }) => `2px solid ${theme.color.primary}`};
    /* background-color: ${({ theme }) => theme.color.brown500}; */
    z-index: -1;
  }
  &.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.color.black80};
  }
  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.color.primary};
  }
`;
