import { ConfigProvider, TabsProps } from "antd";
import {
  AdvertisersManagementLayout,
  ProfileMetrics,
  UserProfile,
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
} from "./AdvertisersManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import { advertisersManagementAntdTheme } from "./AdvertisersManagement.theme";
import ActiveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/ActiveAdvertisersTable";
import InActiveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/InActiveAdvertisersTable";
import ApproveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/ApproveAdvertisersTable";
import type { ColumnsType } from "antd/es/table";
import { abbreviateNumberToK, applyEllipsis } from "@/utils/helper-functions";
import SlideInDrawer from "@/components/Dashboard/SlideInDrawer/SlideInDrawer";
import { useState } from "react";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Avatar from "@/assets/images/avatar.svg";
import Table from "@/components/Dashboard/Table/Table";
import AdsGif from "@/assets/images/ads-gif.gif";

interface AdsDataType {
  key: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
  views: number;
  comments: number;
  likes: number;
  earnings: number;
  date: string;
}

const dummyData: AdsDataType[] = [
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    views: 32000,
    comments: 200,
    likes: 32000,
    earnings: 40,
    date: "12/12/2021",
  },
];

//table column
const adColumns: ColumnsType<AdsDataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoTitle, 37)}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 75)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "35%",
  },
  {
    title: "Views",
    key: "views",
    render: (_, { views, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(views)}
      </StyledTypography>
    ),
    width: "9%",
  },
  {
    title: "Likes",
    key: "likes",
    render: (_, { likes, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(likes)}
      </StyledTypography>
    ),
    width: "10%",
  },
  {
    title: "Comments",
    key: "comments",
    render: (_, { comments, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(comments)}
      </StyledTypography>
    ),
    width: "12%",
  },
  {
    title: "Shekels Given",
    key: "earnings",
    render: (_, { earnings }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {earnings.toLocaleString()} Shekels
      </StyledTypography>
    ),
    width: "16%",
  },
  {
    title: "Date Published",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        Nov 24, 2023
      </StyledTypography>
    ),
    width: "18%",
  },
];

const AdvertisersManagement = () => {
  const onChange = (key: string) => {
    console.log(key);
  };
  const [isOpen, setIsOpen] = useState(false);

  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: "Active Advertisers",
  //     children: <ActiveAdvertisersTable />,
  //   },
  //   {
  //     key: "2",
  //     label: "Inactive Advertisers",
  //     children: <InActiveAdvertisersTable />,
  //   },
  //   {
  //     key: "3",
  //     label: "Approve Advertisers (12)",
  //     children: <ApproveAdvertisersTable />,
  //   },
  // ];

  const handleClick = (id: number) => {
    setIsOpen(true);
  };

  const adsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Uploaded Ads",
      children: (
        <Table
          columns={adColumns}
          dataSource={dummyData}
          pageSize={10}
          isPaginated={true}
        />
      ),
    },
    {
      key: "2",
      label: "Uploaded Posts",
      children: (
        <Table
          columns={adColumns}
          dataSource={dummyData}
          pageSize={10}
          isPaginated={true}
        />
      ),
    },
  ];

  return (
    <ConfigProvider theme={advertisersManagementAntdTheme}>
      {/* view watcher details slide in */}
      <SlideInDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <StyledFlex align="center" gap={38} vertical>
          <StyledFlex $alignSelf="stretch" align="center" gap={16}>
            <UserProfile src={Avatar} alt="" />
            <StyledFlex flex={"1"} align="flex-start" gap={8} vertical>
              <StyledTypography
                $variantColor="black100"
                $variantSize="2xl"
                $fontWeight={600}
              >
                Eva bottling Company
              </StyledTypography>
              <StyledTypography $variantColor="black80" $variantSize="md">
                <StyledFlex as="span" align="center" gap={8}>
                  <span>@evabottlingcompany</span> <span> | </span>
                  <span> sampleofmymail@email.com</span>
                </StyledFlex>
              </StyledTypography>
              <StyledTypography $variantColor="black80" $variantSize="md">
                Date Joined: Nov. 24, 2023
              </StyledTypography>
            </StyledFlex>
          </StyledFlex>
          <ProfileMetrics $alignSelf="stretch" justify="space-between">
            {[
              {
                value: "235",
                title: "Uploaded ads",
              },
              {
                value: "14.5k",
                title: "Likes",
              },
              {
                value: "14.5k",
                title: "Comments",
              },
              {
                value: "14.5k",
                title: "Views",
              },
              {
                value: "250",
                title: "Shekels Given",
              },
            ].map((item) => (
              <StyledFlex
                key={item.title}
                gap={8}
                $alignSelf="stretch"
                align="center"
                vertical
              >
                <StyledTypography
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  {item.value}
                </StyledTypography>
                <StyledTypography $variantColor="black90" $variantSize="xs">
                  {item.title}
                </StyledTypography>
              </StyledFlex>
            ))}
          </ProfileMetrics>

          <StyledFlex align="stretch" gap={16} vertical $alignSelf="stretch">
            <StyledTabs
              defaultActiveKey="1"
              items={adsItems}
              onChange={onChange}
            />
          </StyledFlex>
        </StyledFlex>
      </SlideInDrawer>
      <AdvertisersManagementLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Advertisers Management
        </StyledTypography>
        <StyledDivider />
        <ActiveAdvertisersTable handleRowClick={handleClick} />
        {/* <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
      </AdvertisersManagementLayout>
    </ConfigProvider>
  );
};

export default AdvertisersManagement;
