import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  SearchContainer,
  RevenueTrackingFilterContainerOne,
  RevenueTrackingLayout,
  VideoContentData,
  VideoContentImg,
  VideoContentDataText,
} from "./RevenueTracking.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Download, Search } from "@/assets/vectors";
import { ConfigProvider } from "antd";
import { revenueTrackingAntdTheme } from "./RevenueTracking.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import {
  applyEllipsis,
  convertShekelsToNaira,
  debounce,
} from "@/utils/helper-functions";
import Table from "@/components/Dashboard/Table/Table";
import { shekelsData } from "@/utils/mock-data";
import { useCallback, useState } from "react";
import { PAGINATION_LIMIT, QUERY_KEY_REVENUE_TRACKING } from "@/constants/api";
import { getRevenueTracking } from "@/network/transactions";
import { useQuery } from "@tanstack/react-query";
import Avatar from "@/assets/images/avatar.svg";
import { ISODateString } from "@/types/global";

interface DataType {
  key: string;
  advertisersName: string;
  shekelsConversions: number;
  revenue: number;
  adPlacements: number;
  date: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {videoTitle}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 54)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "30%",
  },
  {
    title: "Advertisers",
    key: "advertisersName",
    render: (_, { advertisersName }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertisersName}
      </StyledTypography>
    ),
    width: "16%",
  },
  {
    title: "Total Revenue",
    key: "revenue",
    render: (_, { revenue }) => (
      <StyledFlex vertical gap={1}>
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {revenue} Shekels
        </StyledTypography>
        <StyledTypography
          $variantColor="gray500"
          $variantSize="xs"
          $fontWeight={400}
        >
          ₦ {convertShekelsToNaira(`${revenue}`)?.toLocaleString()}in Naira
        </StyledTypography>
      </StyledFlex>
    ),
    width: "13%",
  },
  {
    title: "Shekel Conversions",
    key: "shekelsConversions",
    render: (_, { shekelsConversions }) => (
      <StyledFlex vertical gap={1}>
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {shekelsConversions} Shekels
        </StyledTypography>
        <StyledTypography
          $variantColor="gray500"
          $variantSize="xs"
          $fontWeight={400}
        >
          ₦ {convertShekelsToNaira(`${shekelsConversions}`)?.toLocaleString()}{" "}
          in Naira
        </StyledTypography>
      </StyledFlex>
    ),
    width: "17%",
  },
  // {
  //   title: "Ad Placements",
  //   key: "adPlacements",
  //   render: (_, { adPlacements }) => (
  //     <StyledFlex vertical gap={1}>
  //       <StyledTypography
  //         $variantColor="black100"
  //         $variantSize="sm"
  //         $fontWeight={400}
  //       >
  //         {adPlacements} Shekels
  //       </StyledTypography>
  //       <StyledTypography
  //         $variantColor="gray500"
  //         $variantSize="xs"
  //         $fontWeight={400}
  //       >
  //         ₦ {convertShekelsToNaira(`${adPlacements}`)?.toLocaleString()} in
  //         Naira
  //       </StyledTypography>
  //     </StyledFlex>
  //   ),
  //   width: "14%",
  // },
  {
    title: "Date ",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "14%",
  },
];

const RevenueTracking = () => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ month: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);

  const { data: reveueTrackingData, isPending: isPendingRevenueTracking } =
    useQuery({
      queryKey: [QUERY_KEY_REVENUE_TRACKING, page, dateRange, search],
      queryFn: async () =>
        getRevenueTracking({
          limit: PAGINATION_LIMIT,
          search,
          page: page,
          from: dateRange[0] as ISODateString,
          to: dateRange[1] as ISODateString,
        }),
    });

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  if (reveueTrackingData && "error" in reveueTrackingData) {
    return null;
  }

  return (
    <ConfigProvider theme={revenueTrackingAntdTheme}>
      <RevenueTrackingLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Revenue Tracking
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            Tracking Revenue from Shekel Conversions and Ad Placements
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={32} align="center" justify="space-between">
          <StyledFlex gap={16} flex="1" align="center">
            <RevenueTrackingFilterContainerOne>
              <RangePicker
                onChange={(dates) => {
                  if (dates === null) {
                    setDateRange(["", ""]);
                  } else if (dates.length) {
                    setDateRange([
                      dates[0]?.startOf("day").toISO() ?? "",
                      dates[1]?.endOf("day").toISO() ?? "",
                    ]);
                  }
                }}
                value={[
                  dateRange[0] ? DateTime.fromISO(dateRange[0]) : null,
                  dateRange[1] ? DateTime.fromISO(dateRange[1]) : null,
                ]}
                ranges={{
                  Today: [
                    DateTime.now().startOf("day"),
                    DateTime.now().endOf("day"),
                  ],
                  "This Week": [
                    DateTime.now().startOf("week").startOf("day"),
                    DateTime.now().endOf("week").endOf("day"),
                  ],
                  "This Month": [
                    DateTime.now().startOf("month").startOf("day"),
                    DateTime.now().endOf("month").endOf("day"),
                  ],
                  "This Year": [
                    DateTime.now().startOf("year").startOf("day"),
                    DateTime.now().endOf("year").endOf("day"),
                  ],
                }}
              />
            </RevenueTrackingFilterContainerOne>
          </StyledFlex>
          <SearchContainer>
            <StyledInput
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              prefix={<Search />}
              placeholder="Search"
            />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        <StyledFlex vertical gap={16}>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="lg"
            $fontWeight={500}
          >
            Revenue data
          </StyledTypography>
          <Table
            columns={columns}
            pageSize={PAGINATION_LIMIT}
            onPaginationChange={(page) => {
              setPage(page);
            }}
            dataSource={
              reveueTrackingData?.data?.map((data) => ({
                key: data.id,
                advertisersName: data?.user?.profile?.username ?? "",
                shekelsConversions: data?.advertisement?.maxSpend ?? "",
                revenue: data?.amount,
                adPlacements: data?.advertisement?.maxSpend ?? "",
                date: DateTime.fromISO(data?.createdAt ?? "").toFormat(
                  "MMM dd, yyyy"
                ),
                videoImage: data?.advertisement?.thumbnail?.publicURL ?? Avatar,
                videoTitle: data?.advertisement?.title ?? "",
                videoContent: data?.advertisement?.description ?? "",
              })) ?? []
            }
            isPaginated={true}
            total={
              (reveueTrackingData && reveueTrackingData?.totalDocuments) ?? 0
            }
            loading={isPendingRevenueTracking}
          />
        </StyledFlex>
      </RevenueTrackingLayout>
    </ConfigProvider>
  );
};

export default RevenueTracking;
