import React, { ReactNode } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SignIn from "@/routes/Authentication/SignIn/SignIn";
import ForgotPassword from "@/routes/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "@/routes/Authentication/ResetPassword/ResetPassword";
import CreatePassword from "@/routes/Authentication/CreatePassword/CreatePassword";
import DashboardLayout from "@/layouts/DashboardLayout/DashboardLayout";
import Root from "@/routes/Root/Root";
import { DASHBOARD_ROUTES } from "@/routes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Settings from "@/routes/Dashboard/Settings/Settings/Settings";
import AddRole from "./routes/Dashboard/Settings/AddRole/AddRole";
import EditRole from "./routes/Dashboard/Settings/EditRole/EditRole";

const isLoggedIn = !!sessionStorage.getItem("LOHLI_TOKEN");

const flattenedDashboardRoutes = DASHBOARD_ROUTES.reduce(
  (flattenedRoutes, route) => {
    flattenedRoutes.push({ element: route.component, path: route.link });
    if (route.children) {
      flattenedRoutes.push(
        ...route.children.map((item) => ({
          element: item.component,
          path: item.link,
        }))
      );
    }
    return flattenedRoutes;
  },
  [] as {
    element: ReactNode;
    path: string;
  }[]
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/dashboard",
    element: !isLoggedIn ? <Navigate to="/" /> : <DashboardLayout />,
    children: [
      ...flattenedDashboardRoutes,
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "role",
        element: <AddRole />,
      },
      {
        path: "role/:id",
        element: <EditRole />,
      },
    ],
  },
  {
    path: "/signin",
    element: isLoggedIn ? <Navigate to="/" /> : <SignIn />,
  },
  {
    path: "/forgot-password",
    element: isLoggedIn ? <Navigate to="/" /> : <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: isLoggedIn ? <Navigate to="/" /> : <ResetPassword />,
  },
  {
    path: "/verify-signup",
    element: <CreatePassword />,
  },
]);

// Create a client
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
