import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const SettingsLayout = styled(StyledFlex)`
  width: 100%;
`;

export const SettingsAccountLayout = styled(StyledFlex)`
  width: 100%;
  margin-top: 64px;
  padding: 0 32px;
`;
