import { BASE_SIZE_PX } from "@/constants/dom";
import CSS from "csstype";
import { DateTime } from "luxon";

export const pixelToREM = (sizeInPixel: number): CSS.Property.FontSize =>
  `${sizeInPixel / BASE_SIZE_PX}rem`;

export const isPlainObject = (o: any): boolean => o?.constructor === Object;

export const applyEllipsis = (text: string, limit: number) => {
  return text && text.length > limit ? `${text.substring(0, limit)}...` : text;
};

export const abbreviateNumberToK = (num: number): string => {
  if (num >= 1000) {
    if (num % 1000 === 0) {
      return num / 1000 + "k";
    } else {
      return (num / 1000).toFixed(1) + "k";
    }
  } else {
    return num.toString();
  }
};

//reduce array of objects by property
export const reduceArray = <T extends { [key: string]: any }[]>(
  array: T,
  property: string
) => {
  return array.reduce((prev, curr) => prev + curr[property], 0);
};

export const getRandomColor = (): string => {
  // Generate random values for red, green, and blue components
  const red: number = Math.floor(Math.random() * 256); // Random integer between 0 and 255
  const green: number = Math.floor(Math.random() * 256); // Random integer between 0 and 255
  const blue: number = Math.floor(Math.random() * 256); // Random integer between 0 and 255

  // Construct CSS color string in RGB format
  const color: string = `rgb(${red},${green},${blue})`;

  return color;
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number,
  immediate: boolean
) => {
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  return (...args: Parameters<T>) => {
    const context = this;

    clearTimeout(timeout);

    if (immediate && !timeout) {
      func.apply(context, args);
    }

    timeout = setTimeout(() => {
      timeout = undefined;

      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
  };
};

// Arrow function to calculate age based on birthdate
export const calculateAge = (birthdate: Date): number => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  let age = currentDate.getFullYear() - birthdate.getFullYear();

  // Adjust the age if the current date is before the birthdate in the current year
  if (
    currentDate.getMonth() < birthdate.getMonth() ||
    (currentDate.getMonth() === birthdate.getMonth() &&
      currentDate.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  return age;
};

// Function to convert ISO date string to the desired format
export function formatISODateString(isoDateString: string): string {
  // Parse the ISO date string to a DateTime object
  const dateTime = DateTime.fromISO(isoDateString);
  // Get the current DateTime
  const now = DateTime.now();

  // Calculate the difference between the given date and now
  const diff = dateTime
    .diff(now, ["years", "days", "hours", "minutes"])
    .toObject();

  // Determine the appropriate unit to use and format the output
  if (Math.abs(diff.years ?? 0) >= 1) {
    return `${Math.abs(Math.round(diff.years ?? 0))}y`;
  } else if (Math.abs(diff.days ?? 0) >= 1) {
    return `${Math.abs(Math.round(diff.days ?? 0))}d`;
  } else if (Math.abs(diff.hours ?? 0) >= 1) {
    return `${Math.abs(Math.round(diff.hours ?? 0))}h`;
  } else {
    return `${Math.abs(Math.round(diff.minutes ?? 0))}m`;
  }
}

export const convertShekelsToNaira = (shekels: string): number => {
  // Conversion rate from shekels to naira
  const conversionRate = 200;

  // Convert shekels to naira
  return Number(shekels ?? 0) * conversionRate;
};

export const convertNairaToShekels = (naira: string): number => {
  // Conversion rate from naira to shekels
  const conversionRate = 0.005;

  // Convert naira to shekels
  return Number(naira ?? 0) * conversionRate;
};
