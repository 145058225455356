import { IPaginationMeta, ISODateString, Role } from "@/types/global";
import API, { BaseResponse } from "./API";
import Endpoints from "./endpoints";

interface ITransactionParams {
  userId?: number;
  status?: "PENDING" | "SUCCESSFUL" | "FAILED";
  from?: ISODateString;
  to?: ISODateString;
  role?: Role;
  type?: "DEBIT" | "CREDIT";
  search?: string;
  page: number;
  limit: number;
}

interface IReportTransactionRequest {
  transactionId: number;
  message: string;
}

interface IRevenueTrackingParams {
  search?: string;
  from?: ISODateString;
  to?: ISODateString;
  page: number;
  limit: number;
}

interface ITransactionsResponse {
  id: number;
  amount: number;
  exchangeRate: number;
  nairaAmount: number;
  ref: string;
  source: string;
  senderWalletId: number;
  beneficiaryWalletId: number;
  jsonRecord: string;
  advertisementId: number | null;
  isWitholding: boolean;
  description: string;
  status: string;
  type: string;
  purpose: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  senderWallet: {
    user: {
      id: number;
      profile: {
        username: string | null;
        firstName: string | null;
        lastName: string | null;
        gender: string | null;
      };
    };
  };
  beneficiaryWallet: {
    user: {
      id: number;
      profile: {
        username: string | null;
        firstName: string | null;
        lastName: string | null;
        gender: string | null;
      };
    };
  };
  events: {
    id: number;
    transactionId: number;
    status: string;
    createdAt: ISODateString;
    updatedAt: ISODateString;
  }[];
}

interface IRevenueTrackingResponse {
  id: number;
  amount: number;
  actionType: "BUDGET_ALLOCATION";
  userId: number;
  deleted: boolean;
  advertisementId: 2;
  transactionId: number | null;
  commentId: number | null;
  isUserfollowingPoster: boolean;
  postId: number | null;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  user: {
    id: number;
    email: "Albina.Leffler20@gmail.com";
    deactivatedEmail: string | null;
    refreshToken: string | null;
    confirmedRegistration: boolean;
    profileCompleted: boolean;
    submittedBankAccount: boolean;
    updatedProfile: boolean;
    isPinCreated: boolean;
    advertiserInfoId: number | null;
    totalFollowers: number | null;
    totalFollowing: number | null;
    totalTribes: number | null;
    totalAdvertisements: number | null;
    totalSocialPosts: number | null;
    totalCommentLikes: number | null;
    createdAt: ISODateString;
    updatedAt: ISODateString;
    walletId: number | null;
    roleId: number | null;
    currentSubAccountId: number | null;
    paystackCustomerId: number | null;
    paystackCustomerCode: number | null;
    scapaCustomerCode: number | null;
    profileId: number | null;
    inactiveReason: null;
    inactivationExpires: null;
    status: string;
    profile: {
      username: string;
      firstName: string | null;
      lastName: string | null;
      tribes: string[];
    };
  };
  advertisement: {
    id: number;
    userId: number;
    title: string;
    description: string;
    callToActionTitle: string;
    deleted: boolean;
    callToActionURL: string;
    maxSpend: number | null;
    costPerView: number | null;
    metadataId: null;
    locations: string[];
    status: string;
    relevanceScore: number | null;
    relevanceScoredAt: ISODateString;
    videoId: number | null;
    thumbnailId: number | null;
    thumbnail: {
      publicURL: string | null;
      id: number;
    } | null;
    youtubeVideoURL: number | null;
    advertiserSubaccountId: number | null;
    tribeId: number | null;
    createdAt: ISODateString;
    metricsGeneratedAt: ISODateString;
    updatedAt: ISODateString;
    user: {
      profile: {
        username: string | null;
        firstName: string | null;
        lastName: string | null;
        gender: string | null;
        tribes: string[];
      };
    };
  };
}

export const getTransactions = async (
  params: ITransactionParams
): Promise<BaseResponse<ITransactionsResponse[], IPaginationMeta>> => {
  return API.get<ITransactionsResponse[], IPaginationMeta>(
    Endpoints.TRANSACTIONS,
    { params }
  );
};

export const getRevenueTracking = async (
  params: IRevenueTrackingParams
): Promise<BaseResponse<IRevenueTrackingResponse[], IPaginationMeta>> => {
  return API.get<IRevenueTrackingResponse[], IPaginationMeta>(
    Endpoints.REVENUE_TRACKING,
    { params }
  );
};

export const reportTransaction = async (
  payload: IReportTransactionRequest
): Promise<BaseResponse<any>> => {
  return API.post<IReportTransactionRequest, any>(
    Endpoints.REPORT_TRANSACTIONS,
    payload
  );
};
