import { Delete, Plus } from "@/assets/vectors";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import { StyledActionBtn } from "./Role.styles";
import Table from "@/components/Dashboard/Table/Table";
import { Link, useNavigate } from "react-router-dom";

interface DataType {
  key: string;
  role: string;
  noOfMembers: string;
  updatedAt: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    key: "role",
    render: (_, { role }) => (
      <StyledTypography $variantColor="black100" $variantSize="md">
        {role}
      </StyledTypography>
    ),
    width: "35%",
  },
  {
    title: "Team members",
    key: "noOfMembers",
    render: (_, { noOfMembers }) => (
      <StyledTypography $variantColor="black100" $variantSize="sm">
        {noOfMembers}
      </StyledTypography>
    ),
    width: "18%",
  },
  {
    title: "Date modified",
    key: "updatedAt",
    render: (_, { updatedAt }) => (
      <StyledTypography $variantColor="black100" $variantSize="md">
        {updatedAt}
      </StyledTypography>
    ),
    width: "18%",
  },
  {
    title: "",
    key: "key",
    render: (_, { updatedAt }) => (
      <Link to="/dashboard/role/1">
        <StyledTypography $variantColor="primary" $variantSize="md">
          View permissions
        </StyledTypography>
      </Link>
    ),
    width: "18%",
  },
  {
    title: "",
    key: "key",
    render: () => (
      <StyledActionBtn>
        <Delete />
      </StyledActionBtn>
    ),
    width: "11%",
  },
];

const Role: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledFlex vertical gap={32}>
      <StyledFlex align="center" justify="space-between">
        <StyledFlex vertical gap={4}>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="xl-plus"
            $fontWeight={500}
          >
            Roles and permissions
          </StyledTypography>
          <StyledTypography
            $variantColor="gray500"
            $variantSize="md"
            $fontWeight={400}
          >
            Manage and assign the actions that a team member can perform
          </StyledTypography>
        </StyledFlex>
        <StyledButton
          onClick={() => {
            navigate("/dashboard/role");
          }}
          type="primary"
        >
          <Plus />
          <span>Add new role</span>
        </StyledButton>
      </StyledFlex>

      <Table
        columns={columns}
        pageSize={10}
        isPaginated={false}
        dataSource={[
          {
            key: "0",
            role: "Financial Officer",
            updatedAt: "June 10, 2022",
            noOfMembers: "10",
          },
          {
            key: "1",
            role: "Supervisor",
            updatedAt: "June 10, 2022",
            noOfMembers: "10",
          },
          {
            key: "2",
            role: "Agent",
            updatedAt: "June 10, 2022",
            noOfMembers: "10",
          },
          {
            key: "3",
            role: "Chief of staff",
            updatedAt: "June 10, 2022",
            noOfMembers: "10",
          },
        ]}
      />
    </StyledFlex>
  );
};

export default Role;
