import { REFRESH_TOKEN_NAME, TOKEN_NAME } from "@/constants/api";
import axios from "axios";
import Endpoints from "./endpoints";
import { SuccessResponse } from "./API";
import { ITokens } from "./auth";

const API_URL = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common = {
  Authorization: `Bearer ${sessionStorage.getItem(TOKEN_NAME) ?? null}`,
};

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code in the range of 2xx will trigger this function
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Use the refresh token to get a new access token
        const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_NAME);
        const response = await axios.post<
          SuccessResponse<{
            tokens: ITokens;
          }>
        >(`${API_URL}${Endpoints.REFRESH_TOKEN}`, null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const newAccessToken = response.data?.data?.tokens?.accessToken;
        const newRefreshToken = response.data?.data?.tokens?.refreshToken;

        sessionStorage.setItem(TOKEN_NAME, newAccessToken);
        sessionStorage.setItem(REFRESH_TOKEN_NAME, newRefreshToken);

        instance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      } catch (refreshError) {
        // window.location.replace("/");
        return Promise.reject(refreshError);
      }
    }

    // Any status code outside the range of 2xx will trigger this function
    return error.response;
  }
);

export default instance;
