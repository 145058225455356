import styled from "styled-components";

export const StyledStatusTab = styled.button<{
  isActive: boolean;
}>`
  background-color: ${({ theme, isActive }) =>
    `${isActive ? theme.color.gray900 : theme.color.white}`};
  padding: 8px 12px;
  font-size: 16px;
`;
