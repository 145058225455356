import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdvertisersManagementTableContainer,
  AdvestisersImg,
  BanAdsActionButtonContainer,
  EditButtonContainer,
  SearchContainer,
} from "./AdvertisersTable.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import {
  StyledButton,
  StyledDeleteButton,
  StyledExportButton,
} from "@/components/Dashboard/Button/Button.styles";
import { At, Download, Email, Options, Phone, Search } from "@/assets/vectors";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import { activeAdvertisers } from "@/utils/mock-data";
import {
  AdsBanFieldType,
  EditAdvertiserFieldType,
  IActiveAdvertisersTableProps,
} from "./AdvertisersTable.types";
import { useCallback, useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { ConfigProvider, Form, message } from "antd";
import { required } from "@/utils/validation";
import { advertisersManagementTableAntdTheme } from "./AdvertisersTable.theme";
import { PAGINATION_LIMIT, QUERY_KEY_ADVERTISERS } from "@/constants/api";
import {
  banUser,
  deleteUser,
  getAdvertisers,
  suspendUser,
  unSuspendUser,
  updateUserProfile,
} from "@/network/user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { debounce } from "@/utils/helper-functions";
import Avatar from "@/assets/images/avatar.svg";
import Dropdown from "@/components/Dashboard/Dropdown/Dropdown";
import { StatusType } from "@/types/global";
import { StyledStatusTab } from "@/components/Dashboard/StatusTab/StatusTab.styles";

interface DataType {
  key: string;
  userName: string;
  adImg: string;
  uploadedAds: string;
  followers: string;
  views: string;
  date: string;
  status: string;
  id: number;
  phoneNumber: string;
  email: string;
}

const ActiveAdvertisersTable: React.FC<IActiveAdvertisersTableProps> = ({
  handleRowClick,
}) => {
  // const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  // const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [showAEditModal, setShowAEditModal] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; id: number } | null>(null);
  const [actionType, setActionType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<StatusType>("ACTIVE");
  const [id, setId] = useState<number | null>(null);

  const [initialValues, setInitialValues] = useState<EditAdvertiserFieldType>({
    email: "",
    phoneNumber: "",
    username: "",
    agencyName: "",
    agencyType: "",
  });

  const [form] = Form.useForm<EditAdvertiserFieldType>();

  // Access the client
  const queryClient = useQueryClient();

  const { data: advertisersData, isPending: isPendingAdvertisers } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISERS, page, search, activeTab],
    queryFn: async () =>
      getAdvertisers({
        limit: PAGINATION_LIMIT,
        page,
        search,
        status: activeTab,
      }),
  });

  const {
    mutateAsync: mutateDeleteAdvertiser,
    isPending: isPendingDeleteAdvertiser,
  } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateBanAdvertiser,
    isPending: isPendingBanAdvertiser,
  } = useMutation({
    mutationFn: banUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateSuspendAdvertiser,
    isPending: isPendingSuspendAdvertiser,
  } = useMutation({
    mutationFn: suspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateUnSuspendAdvertiser,
    isPending: isPendingUnSuspendAdvertiser,
  } = useMutation({
    mutationFn: unSuspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateEdiAdvertiser,
    isPending: isPendingEdiAdvertiser,
  } = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const handleEdit = async (values: EditAdvertiserFieldType) => {
    console.log(values);
    // Compare the new values with the initial values
    const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== initialValues[key as keyof EditAdvertiserFieldType]) {
        acc[key as keyof EditAdvertiserFieldType] = value;
      }
      return acc;
    }, {} as Partial<EditAdvertiserFieldType>);

    // If no values have changed, return early
    if (Object.keys(updatedValues).length === 0) {
      message.info("No changes detected.");
      return;
    }
    const res = await mutateEdiAdvertiser({
      id: `${id}`,
      ...updatedValues, // Send only updated values
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "Advertiser updated successfully!");
    }
    setShowAEditModal(false);
  };

  // const [form] = Form.useForm<AdsBanFieldType>();

  // const onFinish = (values: AdsBanFieldType) => {
  //   console.log("Success:", values);
  // };

  // const showBanModal = () => {
  //   setIsBanModalOpen(true);
  // };

  // const hideBanModal = () => {
  //   setIsBanModalOpen(false);
  // };

  // const showSuspendModal = () => {
  //   setIsSuspendModalOpen(true);
  // };

  // const hideSuspendModal = () => {
  //   setIsSuspendModalOpen(false);
  // };

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  //table column
  const columns: ColumnsType<DataType> = [
    {
      title: "Advertisers",
      key: "userName",
      render: (_, { adImg, userName, id }) => (
        <StyledFlex
          onClick={() => handleRowClick(id)}
          gap="12px"
          align="center"
        >
          <AdvestisersImg src={adImg} alt="" />
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {userName}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "20%",
    },
    {
      title: "Uploaded Ads",
      key: "uploadedAds",
      render: (_, { uploadedAds, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {uploadedAds}
        </StyledTypography>
      ),
      width: "11%",
    },
    {
      title: "Followers",
      key: "followers",
      render: (_, { followers, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {followers}
        </StyledTypography>
      ),
      width: "9%",
    },
    {
      title: "Views Accrued",
      key: "views",
      render: (_, { views, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {views}
        </StyledTypography>
      ),
      width: "12%",
    },
    {
      title: "Status",
      key: "status",
      render: (_, { status, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {status}
        </StyledTypography>
      ),
      width: "14%",
    },
    {
      title: " Date Joined",
      key: "date",
      render: (_, { date, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date}
        </StyledTypography>
      ),
      width: "10%",
    },
    // {
    //   title: "",
    //   key: "key",
    //   render: () => (
    //     <StyledFlex gap={16} align="center">
    //       <StyledTypography
    //         onClick={showSuspendModal}
    //         as="button"
    //         $variantColor="black90"
    //         $variantSize="sm"
    //         $fontWeight={500}
    //         $underline="underline"
    //       >
    //         Suspend
    //       </StyledTypography>
    //       <StyledTypography
    //         onClick={showBanModal}
    //         as="button"
    //         $variantColor="red"
    //         $variantSize="sm"
    //         $fontWeight={500}
    //         $underline="underline"
    //       >
    //         Ban Advertiser
    //       </StyledTypography>
    //     </StyledFlex>
    //   ),
    //   width: "21%",
    // },
    {
      title: "",
      key: "date",
      render: (_, { id, userName, status, email, phoneNumber }) => (
        <Dropdown
          dropdownRender={() => {
            return <></>;
          }}
          menu={{
            items: [
              {
                label: (
                  <button
                    onClick={() => {
                      setShowAEditModal(true);
                      form.setFieldsValue({
                        email,
                        phoneNumber,
                        username: userName,
                      });
                      setInitialValues({
                        email,
                        phoneNumber,
                        username: userName,
                      });
                      setId(id);
                    }}
                  >
                    <StyledTypography
                      // onClick={() => handleClick(id)}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Edit
                    </StyledTypography>
                  </button>
                ),
                key: "0",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType("delete");
                      }}
                      $variantColor="red"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Delete
                    </StyledTypography>
                  </button>
                ),
                key: "1",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType(
                          status !== "SUSPENDED" ? "suspend" : "unsuspend"
                        );
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      {status !== "SUSPENDED" ? "Suspend" : "Unsuspend"}
                    </StyledTypography>
                  </button>
                ),
                key: "2",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType("ban");
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Ban
                    </StyledTypography>
                  </button>
                ),
                key: "3",
              },
            ],
          }}
        >
          <button>
            <Options />
          </button>
        </Dropdown>
      ),
      width: "6%",
    },
  ];

  if (advertisersData && "error" in advertisersData) {
    return null;
  }

  const advertis =
    advertisersData && "error" in advertisersData
      ? null
      : advertisersData?.data;

  const actionText =
    actionType === "delete"
      ? "Delete"
      : actionType === "ban"
      ? "Ban"
      : actionType === "suspend"
      ? "Suspend"
      : "Unsuspend";

  return (
    <AdvertisersManagementTableContainer gap={32} vertical $alignSelf="stretch">
      {/* modal starts */}
      <ConfigProvider theme={advertisersManagementTableAntdTheme}>
        {/* edit watcher details modal */}
        <Modal
          width={788}
          isModalOpen={showAEditModal}
          setIsModalOpen={setShowAEditModal}
          heading="Edit Profile"
          footer={
            <EditButtonContainer justify="stretch">
              <StyledButton
                onClick={() => {
                  setShowAEditModal(false);
                }}
                type="default"
              >
                Cancel
              </StyledButton>
              <StyledButton
                key="submit"
                htmlType="submit"
                form="edit-advertiser"
                type="primary"
                loading={isPendingEdiAdvertiser}
                disabled={isPendingEdiAdvertiser}
              >
                Update
              </StyledButton>
            </EditButtonContainer>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="edit-advertiser"
            onFinish={handleEdit}
          >
            <StyledFlex gap={16} vertical $alignSelf="stretch">
              <StyledForm.Item<EditAdvertiserFieldType>
                name="agencyName"
                // rules={[required("Please input the first name!")]}
                label="Agency’s name"
              >
                <StyledInput type="text" placeholder="{AgencyName}}" />
              </StyledForm.Item>
              <StyledForm.Item<EditAdvertiserFieldType>
                name="email"
                rules={[
                  required("Please input your Email!"),
                  {
                    type: "email",
                    message: "Please input a valid Email!",
                  },
                ]}
                label="Email"
              >
                <StyledInput
                  disabled
                  prefix={<Email />}
                  type="text"
                  placeholder="{AgencyEmail}"
                />
              </StyledForm.Item>
              <StyledForm.Item<EditAdvertiserFieldType>
                name="phoneNumber"
                // rules={[required("Please input your Phone number!")]}
                label="Phone Number"
              >
                <StyledInput
                  prefix={<Phone />}
                  type="text"
                  placeholder="{AgencyPhoneNumber}"
                />
              </StyledForm.Item>
              <StyledForm.Item<EditAdvertiserFieldType>
                name="username"
                // rules={[required("Please input the username!")]}
                label="User Name"
              >
                <StyledInput
                  prefix={<At />}
                  type="text"
                  placeholder="{UserName}"
                />
              </StyledForm.Item>
              <StyledForm.Item<EditAdvertiserFieldType>
                name="agencyType"
                // rules={[required("Please input the gender!")]}
                label="Agency Type"
              >
                <StyledInput type="text" placeholder="{AgencyType}" />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal>
        {/* delete/ban/suspend/unsuspend modal  */}
        <Modal
          isModalOpen={showActionModal}
          setIsModalOpen={setShowActionModal}
          heading={`Are you sure you want to ${actionText} this Advertiser?`}
          footer={
            <StyledFlex justify="flex-end">
              <StyledButton
                onClick={() => {
                  setShowActionModal(false);
                }}
                type="default"
              >
                Cancel
              </StyledButton>
              <StyledDeleteButton
                loading={
                  isPendingDeleteAdvertiser ||
                  isPendingSuspendAdvertiser ||
                  isPendingUnSuspendAdvertiser ||
                  isPendingBanAdvertiser
                }
                form="suspend-adds"
                key="submit"
                htmlType="submit"
                type="primary"
                onClick={async () => {
                  if (actionType === "delete") {
                    const res = await mutateDeleteAdvertiser({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe deleted successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "ban") {
                    const res = await mutateBanAdvertiser({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe banned successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "suspend") {
                    const res = await mutateSuspendAdvertiser({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe suspended successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "unsuspend") {
                    const res = await mutateUnSuspendAdvertiser({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe unsuspended successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                }}
              >
                Yes, I want to {actionText} this Advertiser
              </StyledDeleteButton>
            </StyledFlex>
          }
        >
          <StyledFlex gap={38} vertical $alignSelf="stretch">
            <StyledTypography as="p" $variantColor="black100" $variantSize="md">
              You are about to {actionText}{" "}
              <StyledTypography
                as="span"
                $variantColor="black100"
                $variantSize="md"
                $fontWeight={500}
              >
                “{user?.name}”.
              </StyledTypography>
            </StyledTypography>
          </StyledFlex>
        </Modal>
        {/* <Modal
          isModalOpen={isBanModalOpen}
          setIsModalOpen={setIsBanModalOpen}
          heading="Are you sure you want Ban the Advertiser?"
          footer={
            <BanAdsActionButtonContainer justify="flex-end">
              <StyledButton onClick={hideBanModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                form="ban-adds"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Yes, I want to ban this Advertiser
              </StyledButton>
            </BanAdsActionButtonContainer>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="add-admin"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                You are about to ban{" "}
                <StyledTypography
                  as="span"
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  “Eva Bottling Company”.
                </StyledTypography>{" "}
                To continue, please give a reason as to why you want to take
                this action.
              </StyledTypography>
              <StyledForm.Item<AdsBanFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea placeholder="eg. Did not meet the terms and condition for Lohli..." />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal> */}
        {/* <Modal
          isModalOpen={isSuspendModalOpen}
          setIsModalOpen={setIsSuspendModalOpen}
          heading="Are you sure you want Suspend the Advertiser?"
          footer={
            <BanAdsActionButtonContainer justify="flex-end">
              <StyledButton onClick={hideSuspendModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                form="suspend-adds"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Yes, I want to suspend this Advertiser
              </StyledButton>
            </BanAdsActionButtonContainer>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="add-admin"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                You are about to suspend{" "}
                <StyledTypography
                  as="span"
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  “Eva Bottling Company”.
                </StyledTypography>{" "}
                To continue, please give a reason as to why you want to take
                this action.
              </StyledTypography>
              <StyledForm.Item<AdsBanFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea placeholder="eg. Did not meet the terms and condition for Lohli..." />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal> */}
      </ConfigProvider>
      {/* modal ends */}
      <StyledFlex vertical gap={32}>
        <StyledFlex>
          {["Active", "Suspended", "Banned"].map((item) => (
            <StyledStatusTab
              key={item}
              isActive={activeTab === item.toLocaleUpperCase()}
              onClick={() => setActiveTab(item.toUpperCase() as StatusType)}
            >
              {item} User
            </StyledStatusTab>
          ))}
        </StyledFlex>

        <StyledFlex justify="space-between" align="center">
          <SearchContainer>
            <StyledInput
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              prefix={<Search />}
              placeholder="Search"
            />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
      </StyledFlex>
      <Table
        className="advertisers-table"
        pageSize={PAGINATION_LIMIT}
        loading={isPendingAdvertisers}
        onPaginationChange={(page) => {
          setPage(page);
          console.log(page);
        }}
        columns={columns}
        dataSource={
          advertisersData && advertisersData?.data.length
            ? advertisersData?.data?.map(
                ({
                  profile,
                  email,
                  totalFollowing,
                  createdAt,
                  id,
                  ...rest
                }) => ({
                  key: id,
                  id,
                  userName: profile?.username ?? "",
                  adImg: profile?.avatar?.publicURL ?? Avatar,
                  uploadedAds: "",
                  followers: totalFollowing,
                  views: "",
                  date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                  status: rest.status,
                  phoneNumber: profile?.phoneNumber ?? "",
                  email,
                })
              )
            : []
        }
        total={(advertisersData && advertisersData?.totalDocuments) ?? 0}
        isPaginated
      />
    </AdvertisersManagementTableContainer>
  );
};

export default ActiveAdvertisersTable;
