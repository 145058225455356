import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdsManagementTableContainer,
  BanAdsActionButtonContainer,
  SearchContainer,
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
} from "./AdsManagementTable.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import {
  StyledButton,
  StyledExportButton,
} from "@/components/Dashboard/Button/Button.styles";
import { Download, Search } from "@/assets/vectors";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import type { ColumnsType } from "antd/es/table";
import { applyEllipsis, debounce } from "@/utils/helper-functions";
import Table from "@/components/Dashboard/Table/Table";
import { totalAds } from "@/utils/mock-data";
import { useNavigate } from "react-router-dom";
import { AdsBanFieldType } from "./AdsManagementTable.types";
import { useCallback, useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { ConfigProvider, Form } from "antd";
import { required } from "@/utils/validation";
import { adManagementTableAntdTheme } from "./AdsManagementTable.theme";
import { PAGINATION_LIMIT, QUERY_KEY_ADVERTISEMENTS } from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { getAdverts } from "@/network/advertisement";
import AdsGif from "@/assets/images/ads-gif.gif";
import { DateTime } from "luxon";

interface DataType {
  id: number;
  key: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
  view: number;
  likes: number;
  comments: number;
  tribe: string;
  date: string;
  advertiser: string;
}

const AdsManagementTable = () => {
  const navigate = useNavigate();
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const { data: advertsData, isPending: isPendingAdverts } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISEMENTS, page, search],
    queryFn: async () =>
      getAdverts({
        limit: PAGINATION_LIMIT,
        page,
        search,
      }),
  });

  const [form] = Form.useForm<AdsBanFieldType>();

  const handleClick = (key: string) => {
    navigate(key);
  };

  const onFinish = (values: AdsBanFieldType) => {
    console.log("Success:", values);
  };
  const showBanModal = () => {
    setIsBanModalOpen(true);
  };

  const hideBanModal = () => {
    setIsBanModalOpen(false);
  };

  const showSuspendModal = () => {
    setIsSuspendModalOpen(true);
  };

  const hideSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  //table column
  const columns: ColumnsType<DataType> = [
    {
      title: "Video Content",
      key: "videoTitle",
      render: (_, { videoContent, videoImage, videoTitle, key }) => (
        <VideoContentData onClick={() => handleClick(key)}>
          <VideoContentImg src={videoImage} alt="" />
          <VideoContentDataText>
            <StyledTypography
              as="p"
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={400}
            >
              {applyEllipsis(videoTitle, 17)}
            </StyledTypography>
            <StyledTypography
              as="p"
              $variantColor="black80"
              $variantSize="sm"
              $fontWeight={400}
            >
              {applyEllipsis(videoContent, 29)}
            </StyledTypography>
          </VideoContentDataText>
        </VideoContentData>
      ),
      width: "30%",
    },
    {
      title: "Advertiser",
      key: "advertiser",
      render: (_, { advertiser, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {advertiser}
        </StyledTypography>
      ),
      width: "12%",
    },
    {
      title: "View",
      key: "view",
      render: (_, { view, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {view}
        </StyledTypography>
      ),
      width: "8%",
    },
    {
      title: "Likes",
      key: "likes",
      render: (_, { likes, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {likes}
        </StyledTypography>
      ),
      width: "8%",
    },
    {
      title: "Comments",
      key: "comments",
      render: (_, { comments, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {comments}
        </StyledTypography>
      ),
      width: "10%",
    },
    {
      title: "Tribe",
      key: "tribe",
      render: (_, { tribe, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {tribe}
        </StyledTypography>
      ),
      width: "9%",
    },
    {
      title: " Date Published",
      key: "date",
      render: (_, { date, key }) => (
        <StyledTypography
          onClick={() => handleClick(key)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date}
        </StyledTypography>
      ),
      width: "14%",
    },
    // {
    //   title: "",
    //   key: "key",
    //   render: () => (
    //     <StyledFlex gap={16} align="center">
    //       <StyledTypography
    //         onClick={showSuspendModal}
    //         as="button"
    //         $variantColor="black90"
    //         $variantSize="sm"
    //         $fontWeight={500}
    //         $underline="underline"
    //       >
    //         Suspend
    //       </StyledTypography>
    //       <StyledTypography
    //         onClick={showBanModal}
    //         as="button"
    //         $variantColor="red"
    //         $variantSize="sm"
    //         $fontWeight={500}
    //         $underline="underline"
    //       >
    //         Ban Ads
    //       </StyledTypography>
    //     </StyledFlex>
    //   ),
    //   width: "21%",
    // },
  ];

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  if (advertsData && "error" in advertsData) {
    return null;
  }

  return (
    <AdsManagementTableContainer gap={32} vertical $alignSelf="stretch">
      {/* modal starts */}
      <ConfigProvider theme={adManagementTableAntdTheme}>
        <Modal
          isModalOpen={isBanModalOpen}
          setIsModalOpen={setIsBanModalOpen}
          heading="Are you sure you want Ban this Advert?"
          footer={
            <BanAdsActionButtonContainer justify="flex-end">
              <StyledButton onClick={hideBanModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                form="ban-adds"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Yes, I want to ban this Advert
              </StyledButton>
            </BanAdsActionButtonContainer>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="add-admin"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                You are about to ban{" "}
                <StyledTypography
                  as="span"
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  “Eva Bottling Company”.
                </StyledTypography>{" "}
                To continue, please give a reason as to why you want to take
                this action.
              </StyledTypography>
              <StyledForm.Item<AdsBanFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea placeholder="eg. Did not meet the terms and condition for Lohli..." />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal>
        <Modal
          isModalOpen={isSuspendModalOpen}
          setIsModalOpen={setIsSuspendModalOpen}
          heading="Are you sure you want Suspend the Advert?"
          footer={
            <BanAdsActionButtonContainer justify="flex-end">
              <StyledButton onClick={hideSuspendModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                form="suspend-adds"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Yes, I want to suspend this Advert
              </StyledButton>
            </BanAdsActionButtonContainer>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="add-admin"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                You are about to suspend{" "}
                <StyledTypography
                  as="span"
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  “Eva Bottling Company”.
                </StyledTypography>{" "}
                To continue, please give a reason as to why you want to take
                this action.
              </StyledTypography>
              <StyledForm.Item<AdsBanFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea placeholder="eg. Did not meet the terms and condition for Lohli..." />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal>
      </ConfigProvider>
      {/* modal ends */}
      <StyledFlex justify="space-between" align="center">
        <SearchContainer>
          <StyledInput
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            prefix={<Search />}
            placeholder="Search"
          />
        </SearchContainer>
        <StyledExportButton type="primary">
          <Download />
          <span>Export</span>
        </StyledExportButton>
      </StyledFlex>
      <Table
        className="ads-management-table"
        columns={columns}
        loading={isPendingAdverts}
        onPaginationChange={(page) => {
          setPage(page);
          console.log(page);
        }}
        dataSource={
          advertsData && advertsData?.data.length
            ? advertsData?.data?.map(
                ({
                  advert: {
                    id,
                    title,
                    description,
                    thumbnail,
                    createdAt,
                    user: {
                      profile: { firstName, lastName },
                    },
                    metadata,
                    tribe,
                  },
                }) => ({
                  view: metadata?.totalViews ?? 0,
                  likes: metadata?.totalLikes ?? 0,
                  comments: metadata?.totalComments ?? 0,
                  key: `${id}`,
                  id,
                  videoImage: thumbnail?.publicURL ?? AdsGif,
                  videoTitle: title,
                  videoContent: description,
                  date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                  advertiser: `${firstName ?? "-"} ${lastName ?? "-"}`,
                  tribe: tribe?.name ?? "-",
                })
              )
            : []
        }
        isPaginated
        total={(advertsData && advertsData.totalDocuments) ?? 0}
      />
    </AdsManagementTableContainer>
  );
};

export default AdsManagementTable;
