import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import ImageUpload from "./ImageUpload";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { Form } from "antd";
import { PasswordFieldType, PersonalInfoFieldType } from "./Profile.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { Email, Phone } from "@/assets/vectors";
import { StyledSelect } from "../../Select/Select.styles";
import { StyledProfileForm, StyledProfileWrapper } from "./Profile.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";

const Profile: React.FC = () => {
  const [form] = Form.useForm<PersonalInfoFieldType>();
  const [passwordForm] = Form.useForm<PasswordFieldType>();

  return (
    <StyledProfileWrapper gap={24} vertical>
      <StyledFlex gap={24} align="center" justify="start">
        <div>
          <ImageUpload />
        </div>
        <StyledFlex $flexGrow={1} gap={4} vertical>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="xl-plus"
            $fontWeight={500}
          >
            Admin Name
          </StyledTypography>
          <StyledTypography
            $variantColor="black90"
            $variantSize="md"
            $fontWeight={400}
          >
            Username
          </StyledTypography>
        </StyledFlex>
      </StyledFlex>
      <StyledFlex gap={20} vertical>
        <StyledDivider />
        <StyledFlex gap={56}>
          <StyledFlex vertical>
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              Personal info
            </StyledTypography>
            <StyledTypography
              $variantColor="black80"
              $variantSize="sm"
              $fontWeight={400}
            >
              View your Agency info and personal <br /> details.
            </StyledTypography>
          </StyledFlex>
          <StyledProfileForm
            form={form}
            layout="vertical"
            name="personal-info"
            onFinish={(values) => {
              console.log(values);
            }}
          >
            <StyledFlex gap={24} vertical $alignSelf="stretch">
              <StyledFlex align="center" gap={24}>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="firstName"
                  rules={[required("Please input the first name!")]}
                  label="First Name"
                >
                  <StyledInput type="text" placeholder="{AdminFirstName}" />
                </StyledForm.Item>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="lastName"
                  rules={[required("Please input the last name!")]}
                  label="Last Name"
                >
                  <StyledInput type="text" placeholder="{AdminLastName}" />
                </StyledForm.Item>
              </StyledFlex>
              <StyledFlex align="center" gap={24}>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="email"
                  rules={[
                    required("Please input your Email!"),
                    {
                      type: "email",
                      message: "Please input a valid Email!",
                    },
                  ]}
                  label="Email"
                >
                  <StyledInput
                    prefix={<Email />}
                    type="text"
                    placeholder="Enter Email"
                  />
                </StyledForm.Item>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="phoneNumber"
                  rules={[required("Please input your Phone number!")]}
                  label="Phone Number"
                >
                  <StyledInput
                    prefix={<Phone />}
                    type="text"
                    placeholder="{WatchersPhoneNumber}"
                  />
                </StyledForm.Item>
              </StyledFlex>
              <StyledForm.Item<PersonalInfoFieldType>
                name="role"
                rules={[required("Please input the role!")]}
                label="Role"
              >
                <StyledSelect placeholder="{AdminRole}" />
              </StyledForm.Item>
            </StyledFlex>
          </StyledProfileForm>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={56}>
          <StyledFlex vertical>
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              Password
            </StyledTypography>
            <StyledTypography
              $variantColor="black80"
              $variantSize="sm"
              $fontWeight={400}
              $mb={"24px"}
            >
              Please enter your current password to <br /> change your password.
            </StyledTypography>

            <StyledButton htmlType="submit" form="password-form" type="primary">
              Change Password
            </StyledButton>
          </StyledFlex>
          <StyledFlex $flexGrow={1} gap={20} vertical>
            <StyledFlex vertical gap={6}>
              <StyledTypography
                $variantColor="black100"
                $variantSize="sm"
                $fontWeight={500}
              >
                Current password
              </StyledTypography>
              <StyledInput disabled type="password" placeholder="••••••••" />
            </StyledFlex>
            <StyledDivider />
            <StyledProfileForm
              form={form}
              layout="vertical"
              name="password-form"
              onFinish={(values) => {
                console.log(values);
              }}
            >
              <StyledFlex gap={24} vertical $alignSelf="stretch">
                <StyledForm.Item<PasswordFieldType>
                  name="newPassword"
                  rules={[required("Please input password!")]}
                  label="New password"
                >
                  <StyledInput type="password" placeholder="••••••••" />
                </StyledForm.Item>
                <StyledForm.Item<PasswordFieldType>
                  name="confirmPassword"
                  rules={[required("Please confirm password!")]}
                  label="Confirm New password"
                >
                  <StyledInput type="password" placeholder="••••••••" />
                </StyledForm.Item>
              </StyledFlex>
            </StyledProfileForm>
          </StyledFlex>
        </StyledFlex>
      </StyledFlex>
    </StyledProfileWrapper>
  );
};

export default Profile;
