import styled from "styled-components";
import { Switch, SwitchProps } from "antd";

export const StyledSwitch = styled(Switch)<SwitchProps>`
  margin-bottom: 0;
  height: 20px;
  min-width: 36px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.black60};
  &.ant-switch .ant-switch-handle {
    height: 16px;
    width: 16px;
  }
  &.ant-switch-checked,
  &.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;
