import { ConfigProvider, TabsProps } from "antd";
import { SettingsAccountLayout, SettingsLayout } from "./Settings.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { settingsAntdTheme } from "./Settings.theme";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import Profile from "@/components/Dashboard/Settings/Profile/Profile";
import Role from "@/components/Dashboard/Settings/Role/Role";
import ThresholdSetup from "@/components/Dashboard/Settings/ThresholdSetup/ThresholdSetup";
import AdminManagement from "@/components/Dashboard/Settings/AdminManagement/AdminManagement";

const Settings = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: (
        <SettingsAccountLayout gap={24} $alignSelf="stretch" vertical>
          <Profile />
        </SettingsAccountLayout>
      ),
    },
    {
      key: "2",
      label: "Account Balance Threshold Setup",
      children: (
        <SettingsAccountLayout gap={24} $alignSelf="stretch" vertical>
          <ThresholdSetup />
        </SettingsAccountLayout>
      ),
    },
    {
      key: "3",
      label: "Role",
      children: (
        <SettingsAccountLayout gap={24} $alignSelf="stretch" vertical>
          <Role />
        </SettingsAccountLayout>
      ),
    },
    {
      key: "4",
      label: "Admin Mangement",
      children: (
        <SettingsAccountLayout gap={24} $alignSelf="stretch" vertical>
          <AdminManagement />
        </SettingsAccountLayout>
      ),
    },
  ];

  return (
    <ConfigProvider theme={settingsAntdTheme}>
      <SettingsLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Settings
        </StyledTypography>
        <StyledDivider />
        <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} />
      </SettingsLayout>
    </ConfigProvider>
  );
};

export default Settings;
