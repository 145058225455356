import RoleForm from "@/components/Dashboard/Settings/RoleForm/RoleForm";

const EditRole: React.FC = () => {
  return (
    <RoleForm
      initialDescription="Manages client relationships and communications, ensures advertisers and watchers have seamless access and support, and oversees financial transactions related to ad revenue and Shekels."
      initialName="Relationship officer "
    />
  );
};

export default EditRole;
