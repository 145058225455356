import { createGlobalStyle } from "styled-components";
import { BASE_SIZE_PX } from "@/constants/dom";
import { globalTheme } from "./theme/theme";
const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: content-box;
        padding: 0;
        margin: 0;
    }
    html {
        font-size: ${`${BASE_SIZE_PX}px`};
    }
    body, html {
        /* overflow-x: hidden; */
    }
    body {
        font-family: 'Lexend', sans-serif;
        font-display: swap;       
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${globalTheme.color.gray50};
    }
    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    /* border: 1px solid #ffffff; */
    -webkit-text-fill-color: ${globalTheme.color.gray90};
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    transition: background-color 5000s ease-in-out 0s;
    }
    #root {
        width: 100%;
    }
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-family:  ${globalTheme.font.fontFamily};
    }
    .ads-management-table, .watchers-table, .advertisers-table {
        .ant-table-row {
            cursor: pointer;
        }
    }
`;

export default GlobalStyles;
